<template>
	<BaseLoading v-if="isLoading" is-full-page />
	<div v-else>
		<div class="page-header">
			<CRow>
				<CCol md="12">
					<h2 class="typo-h4 d-flex align-items-center">
						{{ info.name }}
						<CBadge
							:color="info.period.color"
							class="badge-status"
							data-test-id="badge-order-status"
						>
							{{ info.period.title }}
						</CBadge>
					</h2>
				</CCol>
			</CRow>
			<CRow class="summary">
				<CCol md="12">
					<p class="typo-body-2 m-0 p-0">
						Last updated by: <span class="color-hypertext">{{ updatedBy }}</span><span class="date color-black-45">{{ info.updatedAt }}</span>
					</p>
					<p class="typo-body-2 m-0">
						Created by: <span class="color-hypertext">{{ createdBy }}</span><span class="date color-black-45">{{ info.createdAt }}</span>
					</p>
				</CCol>
			</CRow>
		</div>
		<div class="tabs-container-large mt-4 pb-5">
			<div v-if="steps" class="tabs-wrapper">
				<CTabs
					:active-tab="activeTab"
					@update:activeTab="handleUpdateTab"
				>
					<CTab
						v-for="(step, key) in steps"
						:key="key"
						:title="`Step ${key}: ${step.title}`"
						:disabled="step.disabled"
					>
						<div class="main-wrapper-large">
							<component
								:is="step.component"
								:default-data="step.formData"
								:flash-sale-type="flashSaleType"
								is-edit-mode
								@update:is-valid="handleValidChange(key, $event)"
							/>
						</div>
					</CTab>
				</CTabs>
			</div>
			<div class="navigation-panel">
				<div class="main-wrapper-large">
					<CRow>
						<CCol
							lg="12"
							class="d-flex justify-content-end align-items-center"
						>
							<a
								href="#"
								class="btn-remove mr-auto"
								@click.prevent="handleRemove"
							>
								Remove flash sale
							</a>
							<CButton
								color="tertiary"
								class="transparent mr-3"
								@click.prevent="$router.push({ name: 'FlashSaleList'})"
							>
								Cancel
							</CButton>
							<CButton
								type="button"
								class="transparent"
								color="primary"
								data-test-id="button"
								:disabled="!isEnableSaveChange || edit.isUpdating"
								@click="handleClickSaveChange"
							>
								{{ edit.isUpdating ? 'Saving' : 'Save changes' }}
							</CButton>
						</CCol>
					</CRow>
				</div>
			</div>
		</div>
		<BaseModalDuplicatePeriod
			ref="modal-duplicate-period"
			title="Duplicate period"
			description="There is another flash sale already active on the selected period. If you want to create this flash sale please change period or inactive status."
			primary-button-text="OK"
		/>
		<BaseModalConfirm
			ref="modal-confirm"
			:is-submitting="edit.isUpdating"
			title="Save confirmation?"
			description="The flash sale is ongoing, All changes will take effect on storefront immediately. Do you still want to save?"
			primary-button-text="Confirm save"
			primary-button-loading-text="Saving"
			@onConfirm="handleConfirmSave"
		/>
		<BaseModalConfirmDelete
			ref="modal-remove"
			:handle-remove="deleteFlashSale.bind(null, id)"
			:description="confirmDeleteText"
			title="Remove this flash sale?"
			is-confirm-required
			@onSuccess="$router.push({ name: 'FlashSaleList'})"
		/>
	</div>
</template>

<script>
import { mapState, mapGetters, mapActions } from 'vuex';
import FlashSaleStepGeneralInfo from '@/components/FlashSaleStepGeneralInfo.vue';
import FlashSaleStepProductList from '@/components/FlashSaleStepProductList.vue';
import FlashSaleStepVisibility from '@/components/FlashSaleStepVisibility.vue';
import BaseModalConfirm from '@/components/BaseModalConfirm.vue';
import BaseModalDuplicatePeriod from '@/components/BaseModalDuplicatePeriod.vue';
import { FLASHSALE_LABEL_TITLE_TABS, FLASHSALE_PERIODS_STATUSES } from '../enums/flashSales';
import { DEFAULT_SYSTEM_ADMIN_NAME } from '../enums/general';
import { transformedFlashSaleInfoToDataMultipleStep, transformedFlashSaleDataToAPI } from '../assets/js/transform/flashsales';
import { pathOr } from '../assets/js/helpers';

export default {
	name: 'FlashSaleEdit',
	components: {
		BaseModalConfirm,
		BaseModalDuplicatePeriod,
	},

	data() {
		return {
			id: Number(this.$route.params.id) || null,
			flashSaleType: FLASHSALE_LABEL_TITLE_TABS.GENERAL,
			currentStep: 1,
			totalStep: 0,
			steps: null,
			isLoading: true,
			isDuplicated: false,
		};
	},

	computed: {
		...mapState('flashSales', {
			edit: 'edit',
		}),

		...mapGetters({
			info: 'flashSales/getFlashSaleGeneralInfo',
		}),

		activeTab() {
			return this.currentStep - 1;
		},
		isEnableSaveChange() {
			// Can not save if expired
			if (this.isPeriodStatusExpired) {
				return false;
			}

			let isAllValid = true;
			for (let iStep = 1; iStep <= this.totalStep; iStep++) {
				isAllValid = isAllValid && this.steps[iStep].valid;
			}

			return isAllValid;
		},
		updatedBy() {
			if (this.edit && this.edit.data && this.edit.data.updated_by) {
				return this.edit.data.updated_by.username;
			}

			return DEFAULT_SYSTEM_ADMIN_NAME;
		},
		createdBy() {
			if (this.edit && this.edit.data && this.edit.data.created_by) {
				return this.edit.data.created_by.username;
			}

			return DEFAULT_SYSTEM_ADMIN_NAME;
		},
		periodStatus() {
			const { period: periodStatus } = this.edit?.data || {};
			return periodStatus;
		},
		isPeriodStatusSchedule() {
			return this.periodStatus === FLASHSALE_PERIODS_STATUSES.SCHEDULED;
		},
		isPeriodStatusOngoing() {
			return this.periodStatus === FLASHSALE_PERIODS_STATUSES.ON_GOING;
		},
		isPeriodStatusExpired() {
			return this.periodStatus === FLASHSALE_PERIODS_STATUSES.EXPIRED;
		},
		confirmDeleteText() {
			return this.isPeriodStatusOngoing
				? 'This flash sale is ongoing, Remove this will take effect on storefront immediately. Do you still want to remove this flash sale?'
				: 'Are you sure you want to delete this flash sale?';
		},
	},

	async created() {
		await this.getFlashSale(this.id);

		this.initialStep();

		await this.extractDataToStep();
	},

	methods: {
		...mapActions({
			getFlashSale: 'flashSales/getFlashSale',
			updateFlashSale: 'flashSales/updateFlashSale',
			deleteFlashSale: 'flashSales/deleteFlashSale',
			showMultipleErrorsToast: 'toast/showMultipleErrorsToast',
		}),


		close() {
			this.isDuplicated = false;
		},
		initialStep() {
			this.steps = {
				1: {
					title: FLASHSALE_LABEL_TITLE_TABS.GENERAL_INFO,
					valid: true,
					disabled: false,
					component: FlashSaleStepGeneralInfo,
					formData: null,
				},
				2: {
					title: FLASHSALE_LABEL_TITLE_TABS.PRODUCT_LIST,
					valid: true,
					disabled: false,
					component: FlashSaleStepProductList,
					formData: {},
				},
				3: {
					title: FLASHSALE_LABEL_TITLE_TABS.VISIBILITY,
					valid: true,
					disabled: false,
					component: FlashSaleStepVisibility,
					formData: {},
				},
			};

			this.totalStep = Object.keys(this.steps).length;
		},
		async handleClickSaveChange() {
			// Don't save anymore
			if (this.isPeriodStatusExpired) {
				return;
			}

			// Direclty save
			if (this.isPeriodStatusSchedule) {
				await this.submitForm();
				return;
			}

			// Need confirmation
			if (this.isPeriodStatusOngoing) {
				this.$refs['modal-confirm'].open();
			}
		},
		async handleConfirmSave() {
			await this.submitForm();
		},
		handleUpdateTab(tabIndex) {
			this.currentStep = tabIndex + 1;
		},
		handleValidChange(key, value) {
			if (!key) {
				return;
			}
			const { valid, data: formData } = value;
			this.$set(this.steps, key, { ...this.steps[key], valid, formData });
			// Toggle disabled status for next tab
			const nextIndex = Number(key) + 1;
			if (nextIndex <= this.totalStep) {
				this.$set(this.steps, (nextIndex), { ...this.steps[nextIndex], disabled: !valid });
			}

			for (let index = nextIndex; index <= this.totalStep; index++) {
				if (!valid) {
					// if next tab (n+1) is disabled, the rest must be disabled too.
					this.$set(this.steps, (index), { ...this.steps[index], disabled: true });
				} else if (this.steps[index].valid) {
					// if next tab (n+1) is enabled and already valid, The next two tab (n+2) should be enabled too.
					const nextTwoIndex = index + 1;
					if (nextTwoIndex <= this.totalStep) {
						this.$set(this.steps, (nextTwoIndex), { ...this.steps[nextTwoIndex], disabled: false });
					}
				}

				if (Number(key) === 2) {
					const { flashSaleSkus } = this.steps[2].formData;

					this.$set(this.steps, (3), {
						...this.steps[3],
						formData: {
							...this.steps[3].formData,
							flashSaleSkus,
						},
					});
				}
			}
		},
		handleRemove() {
			this.$refs['modal-remove'].open();
		},
		async submitForm() {
			const postData = transformedFlashSaleDataToAPI({ steps: this.steps });
			try {
				await this.updateFlashSale({ id: this.id, params: postData });
			} catch (error) {
				const statusCode = pathOr(null, ['response', 'status'])(error);
				const isErrorOverlap = !!(pathOr(false, ['response', 'data', 'errors', 'date_time', 0])(error));

				// TODO: Refactor this condition, api has to send error response in the same structure
				if (statusCode === 422) {
					if (isErrorOverlap) {
						this.isDuplicated = true;
						this.$refs['modal-duplicate-period'].open();
					} else {
						this.showMultipleErrorsToast(error);
					}
				}
			}
		},
		async extractDataToStep() {
			if (!this.edit.data) { return; }

			const flashSaleData = await transformedFlashSaleInfoToDataMultipleStep(this.edit.data);
			if (flashSaleData) {
				this.steps[1].formData = flashSaleData.steps[1].formData;
				this.steps[2].formData = flashSaleData.steps[2].formData;
				this.steps[3].formData = flashSaleData.steps[3].formData;
			}
			this.isLoading = false;
		},
	},
};
</script>

<style lang="scss" scoped>
	.page-header {
		margin: 0 rem(-28);
		padding: 0 rem(28);
	}

	.summary {
		.date {
			&::before {
				content: "|";
				display: inline-block;
				margin-left: rem(5);
				margin-right: rem(5);
			}
		}
	}

	.badge-status {
		width: auto;
		padding: rem(7) rem(8);
		margin-left: rem(16);
		font-size: rem(14);
	}

	.navigation-panel {
		position: fixed;
		left: rem(56);
		right: 0;
		bottom: 0;
		padding: rem(12) 0;
		box-shadow: 0 1px 2px 0 $color-gray-07, 0 4px 16px 0 $color-gray-15;
		background-color: $color-white;
		z-index: 9;

		@media (max-width: 991.98px) {
			left: 0;
		}

		::v-deep button {
			min-width: rem(80);
		}
	}
</style>
